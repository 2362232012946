
import { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import {  UsersServerStateKeysEnum } from '../../../hooks/api/users'
import { ActiveEnterpriseContext } from '../../../context/activeEnterpriseContext'
import { useFormik } from 'formik'
import theme from '../../../config/theme'
import ContainedButton from '../../common/buttons/containedButton'
import { useUpdateMemberShip } from '../../../hooks/api/memberShip'
import Loader from '../../common/loader'
import { useQueryClient } from 'react-query'

const NotifsMemberShip = () => {
    const [updatedValues, setUpdatedValues] = useState(
        {
            type: 'cotisation',
            item_id: '',
            quantity: 1,
            region_code: '',
            member_id: ''
    });
    const { activeEnterprise } = useContext(ActiveEnterpriseContext);
    const query = useQueryClient()
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...updatedValues
        },
        onSubmit: async () => {
          await updateMemberShip(
            {
                type: 'cotisation',
                item_id: activeEnterprise?.renew_membership_product_crm_id,
                quantity: 1,
                region_code: activeEnterprise?.region?.code,
                member_id: activeEnterprise?.crm_id
            },
            {onSuccess: async () => {
                await query.invalidateQueries(UsersServerStateKeysEnum.Session)
                    
              },
            }
          )
        },
      })
        const { mutate: updateMemberShip, status } = useUpdateMemberShip(formik)

    return  (activeEnterprise?.display_renewal_button)?
        (<Box sx={{padding: '10px 5px',
            border: '1px solid #bcbcbc',
            marginTop: '-70px',
            marginBottom: '70px',
            display:'flex',
            alignItems:'center',
            gap:'10px',
            background: '#fff8ec',
            width: 'calc(100% + 86px)',
            marginLeft: '-49px'
            }}>
             <WarningAmberRoundedIcon htmlColor={theme.palette.secondary.main}/> 
             <Typography variant='bodySmall'>
             {(activeEnterprise?.expired_membership) ? 
             'Attention! Votre abonnement est expiré. Veuillez cliquer sur le bouton pour renouveler votre abonnement.'
             :
             'Attention! Votre abonnement sera bientôt expiré. Veuillez clicker sur le boutton pour renouveler votre abonnement.'
              }
            </Typography>

            <ContainedButton title='Renouveler' handleClick={()=>formik.handleSubmit()}></ContainedButton>
            {status ==='loading' ?  <Loader /> : status === 'success' ? 
                <Box>
                <Typography variant='captionSmall'>Votre abonnement a été ajouté au panier avec succès</Typography>
                </Box>
            :null}
                
        </Box>) : null
}
export default NotifsMemberShip