import { useMemo } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { t } from 'i18next'
import SectionForm from '../../common/sectionForm'
import EditButtons from '../../common/editButtons'
import { EditableContext } from '../../../context/editableContext'
import FormikContext from '../../../context/formikContext'
import ReadOnlyField from '../../common/fields/readOnlyField'
import EditableTextField from '../../common/fields/editableTextField'
import OutlinedFieldFormik from '../../formik/fields/outlinedFieldFormik'
import SelectMultipleFormik from '../../formik/fields/selectMultipleFormik'
import DatePickerFieldFormik from '../../formik/fields/datePickerFieldFormik'
import SectionFormAccordion from '../../common/sectionFormAccordion'
import { Typography } from '@mui/material'
import { getDayAndMonthName, getNestedObject, getYear } from '../../../utils'
import PhoneFieldFromik from '../../formik/fields/phoneFieldFromik'
import EmailFieldFormik from '../../formik/fields/emailFieldFormik'
import AmountFieldFromik from '../../formik/fields/amountFieldFormik'
import { FormikProps } from 'formik'
import useIsGrantedToUpdate from '../../../hooks/useIsGrantedToUpdate'
interface Props {
  formik: FormikProps<any>
  isEditable: boolean
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>
}
const listItems = [
  {
    name: 'Commercial',
    id: 'Commercial',
  },
  {
    name: 'Industriel',
    id: 'Industriel',
  },
  {
    name: 'Institutionnel',
    id: 'Institutionnel',
  },
  {
    name: 'Génie civil',
    id: 'Genie_civil',
  },
  {
    id: 'Voirie',
    name: 'Voirie',
  },
  {
    name: 'Résidentiel - Rénovation',
    id: 'Residentiel_renovation',
  },
  {
    name: 'Résidentiel - Constructeur',
    id: 'Residentiel_constructeur', 
  },
]

const translate = (item: string) => t(`pages.entrepriseProfile.general.${item}`)!
const GeneralForm = ({ isEditable, setIsEditable, formik }: Props) => {
  const getValue = (property: string) => {
    return (getNestedObject(property, formik.values) || '') as string
  }
  const editableState = useMemo(() => ({ isEditable, setIsEditable }), [isEditable, setIsEditable])
  const isGrantedToUpdate = useIsGrantedToUpdate()
  return (
    <EditableContext.Provider value={editableState}>
      <FormikContext.Provider value={formik}>
        <Box component='form' sx={{ pt: '48px', position: 'relative', overflow: 'hidden' }}>
          {isGrantedToUpdate && (
            <EditButtons sx={{ position: 'absolute', top: '48px', right: '0' }} />
          )}
          <Stack spacing='30px' mt={isGrantedToUpdate ? '66px' : '0'} mb='30px'>
            <SectionForm title={translate('aboutEntreprise.title')}>
              <Stack spacing='27px'>
                <Box sx={{ display: 'flex' }}>
                  <ReadOnlyField
                    label={translate('aboutEntreprise.legalName')}
                    value={getValue('about.legal_name')}
                    sx={{ width: '46%', mr: '6%' }}
                  />
                  <ReadOnlyField
                    label={translate('aboutEntreprise.RGB')}
                    value={getValue('about.no_rbq')}
                    sx={{ width: '22%', mr: '2%' }}
                  />
                  <ReadOnlyField
                    label={translate('aboutEntreprise.RBGIssueDate')}
                    value={getValue('about.rbq_issue_date') || 'Non disponible'}
                    sx={{ width: '22%' }}
                  />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <EditableTextField
                    label={translate('aboutEntreprise.usualName')}
                    sx={{ width: '46%', mr: '6%' }}
                    value={getValue('about.usual_name')}
                  >
                    <OutlinedFieldFormik
                      name='about.usual_name'
                      placeholder={translate('aboutEntreprise.usualName')}
                      sx={{ maxWidth: '469px' }}
                    />
                  </EditableTextField>
                  <ReadOnlyField
                    label={translate('aboutEntreprise.RBQRenewal')}
                    value={getDayAndMonthName(getValue('about.rbq_renovation_date'))}
                    sx={{ width: '22%', mr: '2%' }}
                  />
                  <ReadOnlyField
                    label={translate('aboutEntreprise.CCQ')}
                    value={getValue('about.no_ccq')}
                    sx={{ width: '22%' }}
                  />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <EditableTextField
                    label={translate('aboutEntreprise.activityArea')}
                    sx={{ width: '46%', mr: '6%' }}
                    value={listItems
                      .filter((option) => getValue('about.business_sectors').includes(option.id))
                      .map((option) => option.name)
                      .join(', ')}
                  >
                    <SelectMultipleFormik
                      name='about.business_sectors'
                      placeholder={translate('aboutEntreprise.activityArea')}
                      listItems={listItems}
                      selectType={true}
                      sx={{ maxWidth: '469px' }}
                    />
                  </EditableTextField>
                  <ReadOnlyField
                    label={translate('aboutEntreprise.BSDQ')}
                    value={getValue('about.no_bsdq')}
                    sx={{ width: '22%', mr: '2%' }}
                  />
                  <ReadOnlyField
                    label={translate('aboutEntreprise.NEQ')}
                    value={getValue('about.no_neq')}
                    sx={{ width: '22%' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', width: '46%', maxWidth: '469px' }}>
                  <EditableTextField
                    label={translate('aboutEntreprise.foundingDate')}
                    sx={{ width: '100%', mr: '5%' }}
                    value={getYear(getValue('about.founding_date')) || '_'}
                  >
                    <DatePickerFieldFormik
                      name='about.founding_date'
                      placeholder={translate('aboutEntreprise.foundingDate')}
                      sx={{ maxWidth: '222px' }}
                    />
                  </EditableTextField>
                  <EditableTextField
                    label={translate('aboutEntreprise.numberOfEmployees')}
                    sx={{ width: '100%' }}
                    value={getValue('about.number_of_employees') || '_'}
                  >
                    <AmountFieldFromik
                      name='about.number_of_employees'
                      placeholder={translate('aboutEntreprise.numberOfEmployees')}
                      sx={{ maxWidth: '225px' }}
                    />
                  </EditableTextField>
                </Box>
                <EditableTextField
                  label={translate('aboutEntreprise.turnover')}
                  sx={{ width: '22%' }}
                  value={getValue('about.revenue') || '_'}
                >
                  <AmountFieldFromik
                    name='about.revenue'
                    placeholder={translate('aboutEntreprise.turnover')}
                    sx={{ maxWidth: '225px' }}
                  />
                </EditableTextField>
              </Stack>
            </SectionForm>
            <SectionFormAccordion
              title={translate('contactDetails.title')}
              subTitle={translate('contactDetails.mainAddress')}
            >
              <Stack spacing='34px'>
                <Box sx={{ display: 'flex' }}>
                  <ReadOnlyField
                    label={t('common.civicNumber')}
                    value={getValue('address.civic_number')}
                    sx={{ width: '85px', mr: '24px' }}
                  />
                  <ReadOnlyField
                    label={t('common.street')}
                    value={getValue('address.street')}
                    sx={{ width: '225px', mr: '24px' }}
                  />
                  <ReadOnlyField label={t('common.office')} value={getValue('address.office')} />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <ReadOnlyField
                    label={t('common.country')}
                    value={getValue('address.country')}
                    sx={{ width: '334px', mr: '24px' }}
                  />
                  <ReadOnlyField
                    label={t('common.province')}
                    value={getValue('address.province')}
                  />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <ReadOnlyField
                    label={t('common.city')}
                    value={getValue('address.city')}
                    sx={{ width: '334px', mr: '24px' }}
                  />
                  <ReadOnlyField
                    label={t('common.postalCode')}
                    value={getValue('address.postal_code')}
                  />
                </Box>
                <Typography variant='bodySmall'>
                  {translate('contactDetails.phraseMainAddress')}
                </Typography>
              </Stack>
            </SectionFormAccordion>
            <SectionFormAccordion subTitle={translate('contactDetails.mailingAddress')}>
              <Stack spacing='34px'>
                <Box sx={{ display: 'flex' }}>
                  <ReadOnlyField
                    label={t('common.civicNumber')}
                    value={getValue('correspondence_address.civic_number')}
                    sx={{ width: '85px', mr: '24px' }}
                  />
                  <ReadOnlyField
                    label={t('common.street')}
                    value={getValue('correspondence_address.street')}
                    sx={{ width: '225px', mr: '24px' }}
                  />
                  <ReadOnlyField
                    label={t('common.office')}
                    value={getValue('correspondence_address.office')}
                  />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <ReadOnlyField
                    label={t('common.country')}
                    value={getValue('correspondence_address.country')}
                    sx={{ width: '334px', mr: '24px' }}
                  />
                  <ReadOnlyField
                    label={t('common.province')}
                    value={getValue('correspondence_address.province')}
                  />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <ReadOnlyField
                    label={t('common.city')}
                    value={getValue('correspondence_address.city')}
                    sx={{ width: '334px', mr: '24px' }}
                  />
                  <ReadOnlyField
                    label={t('common.postalCode')}
                    value={getValue('correspondence_address.postal_code')}
                  />
                </Box>
              </Stack>
            </SectionFormAccordion>
            <SectionFormAccordion subTitle={translate('contactDetails.joinUs')}>
              <Box sx={{ display: 'flex', mb: '27px' }}>
                <EditableTextField
                  label={t('common.email')}
                  sx={{ width: '222px', mr: '24px' }}
                  value={getValue('reach_us.email')}
                  required
                >
                  <EmailFieldFormik name='reach_us.email' />
                </EditableTextField>
                <EditableTextField
                  label={translate('contactDetails.website')}
                  sx={{ width: '222px' }}
                  value={getValue('reach_us.website')}
                >
                  <OutlinedFieldFormik
                    name='reach_us.website'
                    placeholder='https://votresiteweb.ca'
                  />
                </EditableTextField>
              </Box>
              <Box sx={{ display: 'flex', mb: '27px' }}>
                <EditableTextField
                  label={t('common.phone')}
                  sx={{ width: '204px', mr: '42px' }}
                  value={getValue('reach_us.phone_number')}
                  required
                >
                  <PhoneFieldFromik name='reach_us.phone_number' />
                </EditableTextField>
                <EditableTextField
                  label={translate('contactDetails.tollFreePhone')}
                  sx={{ width: '204px', mr: '24px' }}
                  value={getValue('reach_us.toll_free_phone_number')}
                >
                  <PhoneFieldFromik
                    name='reach_us.toll_free_phone_number'
                    placeholder={'800 123-1234'}
                  />
                </EditableTextField>
                <EditableTextField
                  label={translate('contactDetails.faxMachine')}
                  sx={{ width: '204px' }}
                  value={getValue('reach_us.fax')}
                >
                  <PhoneFieldFromik name='reach_us.fax' />
                </EditableTextField>
              </Box>
              <Typography variant='bodySmall'>
                {translate('contactDetails.phraseJoinUs')}
              </Typography>
            </SectionFormAccordion>
            <SectionFormAccordion
              subTitle={translate('bondsAndGuatantees.RBQLicenseBond')}
              title={translate('bondsAndGuatantees.title')}
            >
              <Box sx={{ display: 'flex' }}>
                <ReadOnlyField
                  label={translate('bondsAndGuatantees.status')}
                  value={getValue('rbq_license_deposit.status')}
                  sx={{ width: '222px', mr: '24px' }}
                />
                <ReadOnlyField
                  label={translate('bondsAndGuatantees.effectiveDate')}
                  value={getValue('rbq_license_deposit.effective_date')}
                  sx={{ width: '222px', mr: '24px' }}
                />
                <ReadOnlyField
                  label={t('common.noBond')}
                  value={getValue('rbq_license_deposit.no_deposit')}
                  sx={{ width: '222px', mr: '24px' }}
                />
                <ReadOnlyField
                  label={translate('bondsAndGuatantees.amount')}
                  value={
                    getValue('rbq_license_deposit.amount')
                      ? `${getValue('rbq_license_deposit.amount')} $`
                      : ''
                  }
                />
              </Box>
            </SectionFormAccordion>
            <SectionFormAccordion subTitle={translate('bondsAndGuatantees.PGAGuaranteePlan')}>
              <Box sx={{ display: 'flex' }}>
                <ReadOnlyField
                  label={translate('bondsAndGuatantees.accreditationDate')}
                  value={getValue('pga_guarantee_plan.accreditation_date')}
                  sx={{ width: '222px', mr: '24px' }}
                />
                <ReadOnlyField
                  label={translate('bondsAndGuatantees.noResidentialBond')}
                  value={getValue('pga_guarantee_plan.residential_deposit_number')}
                  sx={{ width: '225px', mr: '24px' }}
                />
                <ReadOnlyField
                  label={translate('bondsAndGuatantees.PGAAccreditationNo')}
                  value={getValue('pga_guarantee_plan.no_accreditation')}
                />
              </Box>
            </SectionFormAccordion>
            <SectionFormAccordion subTitle={translate('bondsAndGuatantees.GCRGuaranteePlan')}>
              <Box sx={{ display: 'flex' }}>
                <ReadOnlyField
                  label={translate('bondsAndGuatantees.dateOfIssueOfBond')}
                  value={getDayAndMonthName(getValue('gcr_guarantee_plan.date_deposit_issue'))}
                  sx={{ width: '222px', mr: '24px' }}
                />
                <ReadOnlyField
                  label={t('common.noBond')}
                  value={getValue('gcr_guarantee_plan.no_deposit')}
                />
              </Box>
            </SectionFormAccordion>
            <SectionFormAccordion subTitle={translate('bondsAndGuatantees.CNESST')}>
              <Box sx={{ display: 'flex' }}>
                <ReadOnlyField
                  label={translate('bondsAndGuatantees.noClassification')}
                  value={getValue('cnesst.no_classification')}
                  sx={{ width: '222px', mr: '24px' }}
                />
                <ReadOnlyField
                  label={translate('bondsAndGuatantees.noEmployer')}
                  value={getValue('cnesst.no_employer')}
                  sx={{ width: '225px', mr: '24px' }}
                />
                <ReadOnlyField
                  label={translate('bondsAndGuatantees.contribution')}
                  value={getValue('cnesst.contribution')}
                />
              </Box>
            </SectionFormAccordion>
          </Stack>
          {isGrantedToUpdate && <EditButtons sx={{ float: 'right' }} />}
        </Box>
      </FormikContext.Provider>
    </EditableContext.Provider>
  )
}

export default GeneralForm
