import { AxiosConfigType } from '../types'
import  { City,AddressPostal } from '../types/api/city'
import  Axios  from './index'
const basePath = '/cities'
const findAll = async (name: string, config?: AxiosConfigType) => {
  const response = await Axios.get<City[]>(`${basePath}?name=${name}`, config)
  return response.data
}
const findCodePostalByCityID = async (crm_id: string,code: string, config?: AxiosConfigType) => {
  const response = await Axios.get<AddressPostal[]>(`${basePath}/${crm_id}/postal-codes?code=${code}`, config)
  return response.data
}


const CityApi = {
  findAll,
  findCodePostalByCityID
}

export default CityApi
