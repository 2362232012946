import { Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { MembershipType } from '../types/api/entreprise'
import ReadOnlyField from './common/fields/readOnlyField'
import SectionForm from './common/sectionForm'
import React, { useMemo } from 'react'
import { addUniqueKeyToList, getDayAndMonthNameYear } from '../utils'
import { uniqueKey } from '../constants'
import { t } from 'i18next'
interface Props {
  data: MembershipType | undefined
}
interface TypographyProps {
  children: React.ReactNode
}
const TypographyComponent = ({ children }: TypographyProps) => {
  return <Typography component='span'>{children}</Typography>
}
const returnEmptyIfNull = (value: string | undefined | null) => (value ? value : <>&nbsp;</>)

const MembershipTab = ({ data }: Props) => {
  const referrals = useMemo(() => addUniqueKeyToList(data?.referrals), [data?.referrals])
  return (
    <Stack spacing='53px' mt='48px'>
      <SectionForm title='Adhésion'>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '48%', mr: '5%', display: 'flex' }}>
            <Stack spacing='34px' sx={{ flex: 1, mr: '5%' }}>
              <ReadOnlyField
                label='Adhésion'
                value={returnEmptyIfNull(
                  data?.membership?.membership_date
                    ? getDayAndMonthNameYear(data.membership.membership_date)
                    : null,
                )}
              />
              <ReadOnlyField
                label='Date de renouvellement'
                value={returnEmptyIfNull(
                  data?.membership?.renewal_date
                    ? getDayAndMonthNameYear(data.membership.renewal_date)
                    : null,
                )}
              />
              <ReadOnlyField
                label={t('pages.entrepriseProfile.general.membership.nummeber_provincial')}
                value={returnEmptyIfNull(data?.membership?.provincial_membership_number)}
              />
            </Stack>
            <Stack spacing='34px' sx={{ flex: 1 }}>
              <ReadOnlyField label='Statut' value={returnEmptyIfNull(data?.membership?.status)} />
              <ReadOnlyField
                label='Catégorie de membre'
                value={returnEmptyIfNull(data?.membership?.company_category)}
              />
              <ReadOnlyField
                label={t('pages.entrepriseProfile.general.membership.nummeber_regional')}
                value={returnEmptyIfNull(data?.membership?.regional_membership_number)}
              />
            </Stack>
          </Box>
          <Box sx={{ width: '40%' }}>
            <ReadOnlyField
              label='Succursale ACQ régionale'
              value={
                <Stack>
                  {data?.acq_regional_branch?.name && (
                    <TypographyComponent>{data.acq_regional_branch.name}</TypographyComponent>
                  )}
                  {data?.acq_regional_branch?.phone_number && (
                    <TypographyComponent>
                      {data.acq_regional_branch.phone_number}
                    </TypographyComponent>
                  )}
                  {data?.acq_regional_branch?.email && (
                    <TypographyComponent>{data.acq_regional_branch.email}</TypographyComponent>
                  )}
                  {data?.acq_regional_branch?.address && (
                    <TypographyComponent>{data.acq_regional_branch.address}</TypographyComponent>
                  )}
                  {data?.acq_regional_branch?.city && (
                    <TypographyComponent>{data?.acq_regional_branch?.city}</TypographyComponent>
                  )}
                  {data?.acq_regional_branch?.postal_code && (
                    <TypographyComponent>
                      {data.acq_regional_branch.postal_code}
                    </TypographyComponent>
                  )}
                </Stack>
              }
            />
          </Box>
        </Box>
      </SectionForm>
      {data?.referrals && data?.referrals?.length > 0 && (
        <SectionForm title='entreprise(s) référé(s)'>
          <Stack
            spacing='16px'
            sx={{
              'div:last-child': {
                borderBottom: 'none',
                pb: '0',
              },
            }}
          >
            {referrals.map((referral) => (
              <Box
                key={referral[uniqueKey]}
                sx={{
                  width: '100%',
                  display: 'flex',
                  pb: '26px',
                  borderBottom: '1px solid #DADCDD',
                }}
              >
                <Box sx={{ width: '26%' }}>
                  <ReadOnlyField
                    label='No du référé'
                    value={returnEmptyIfNull(referral?.provincial_membership_number)}
                  />
                </Box>
                <Box sx={{ width: '26%' }}>
                  <ReadOnlyField
                    label='Succursale ACQ régionale'
                    value={returnEmptyIfNull(referral?.acq_provincial_name)}
                  />
                </Box>
              </Box>
            ))}
          </Stack>
        </SectionForm>
      )}
      {data?.referred_by && (
        <SectionForm title='entreprise référante'>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
            }}
          >
            <Box sx={{ width: '26%' }}>
              <ReadOnlyField
                label='No du référant'
                value={returnEmptyIfNull(data?.referred_by?.provincial_membership_number)}
              />
            </Box>
            <Box sx={{ width: '26%' }}>
              <ReadOnlyField
                label='Succursale ACQ régionale'
                value={returnEmptyIfNull(data?.referred_by?.acq_provincial_name)}
              />
            </Box>
          </Box>
        </SectionForm>
      )}
    </Stack>
  )
}

export default MembershipTab
